<template>
  <Overlay :show="value">
    <div class="qr">
      <img :src="src" alt="" class="qr_img" />
      <p style="padding: 0 20px">{{text ? text : '长按识别二维码 加好友'}}</p>
      <img
        src="../assets/images/关闭.png"
        alt=""
        class="close"
        @click="close"
      />
    </div>
  </Overlay>
</template>
<script>
import { Overlay } from "vant";
export default {
  props: {
    value: {
      default: false,
    },
    src: {
      default: "",
    },
    text: {
      default: ''
    }
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    close() {
      this.$emit("change", false);
    },
  },
  components: { Overlay },
};
</script>

<style scoped lang="less">
.qr {
  width: 80%;
  height: 578px;
  background: #ffffff;
  border: 1px solid rgba(132, 109, 74, 0.92);
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 338px;
  position: relative;
}
.qr_img {
  width: 60%;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-top: 60px;
}
p {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #363636;
  margin-top: 54px;
  text-align: center;
}
.close {
  width: 72px;
  height: 72px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -100px;
}
</style>
