// 1：浏览文章
// 2：浏览个人主页
// 3：浏览名片
// 4：转化商机
// 5：点击广告
// 6：添加个人微信好友
// 7：添加企业微信好友
// 8：拨打电话
// 9：分享名片
// 10：点赞名片
// 11：分享文章
// 12：点赞文章
// 13：收藏文章
// 14：发送名片

var $url;
export default {
  data() {
    return {
      form: {
        businessCard: {},
        ad: {},
        article: {},
      },
      time: null,
      mobile: "",
      title: "分享",
      desc: "海平创新-请供应链金融就找海平老师",
      skeleton: true
    }
  },
  created() {
    if (this.$route.query.tenantId) {
      localStorage.setItem("tenantId", this.$route.query.tenantId);
    }
    if (this.getQueryVariable("code")) {
      this.$api
        .post("/api/wechat/getH5UserInfo", {
          tenantId: localStorage.getItem("tenantId"),
          code: this.getQueryVariable("code"),
          pid: this.$route.query.fromOpenId,
          ladingPageId: (this.$route.query.businessCardId ? this.$route.query.id : 0)
        })
        .then((res) => {
          console.log('微信登录返回信息：', res)
          this.$cookies.set("unionId", res.result.unionId);
          this.$cookies.set("openId", res.result.openId);
          this.$cookies.set(`token_${localStorage.getItem('tenantId')}`, res.result.token, "12h");
          this.$cookies.set("mobile", res.result.mobile || '');
          this.$cookies.set("headpic", res.result.headPic);
          this.$cookies.set("nickname", res.result.nickname);
          window.location.href = window.location.origin + window.location.pathname + window.location.hash
        });
    } else if (!this.$cookies.get(`token_${localStorage.getItem('tenantId')}`)) {
      this.$api
        .get("/api/wechat/getAuthorizeUrl", {
          url: window.location.href
        })
        .then((res) => {
          console.log('获取微信登录url：', res.result)
          if (process.env.NODE_ENV != 'development') {
            window.location.href = res.result;
          } else { // 本地写死用户信息
            this.$cookies.set("unionId", '');
            this.$cookies.set("openId", 'oyODKwPV1l46Tg8zxEurBdq3EH7o');
            this.$cookies.set(`token_${localStorage.getItem('tenantId')}`, '88888', "12h");
            this.$cookies.set("headpic", '');
            this.$cookies.set("nickname", '张小菇');
            this.$cookies.set("mobile", '13076997010');
            window.location.href = window.location.origin + window.location.pathname + window.location.hash
          }
        });
      // if (process.env.NODE_ENV === 'development') {
      //   this.$cookies.set("unionId", '');
      //   this.$cookies.set("openId", 'oyODKwE3fPWZAoFJPuwPjWtF4zgA');
      //   // this.$cookies.set("mobile", '1625279693275');
      //   this.$cookies.set(`token_${localStorage.getItem('tenantId')}`, '88888', "12h");
      //   this.$cookies.set("headpic", 'https%3A%2F%2Fthirdwx.qlogo.cn%2Fmmopen%2Fvi_32%2F27gULGP2r90XPahmyibhY0VBCO99K5ibNkCQtHj5TAeVgJ35jxY6FUm1RewxaPkchRLsbFZ6UIR6Ex3G4sp83GCQ%2F132');
      //   this.$cookies.set("nickname", '张小菇');
      //   window.location.href = window.location.origin + window.location.pathname + window.location.hash
      // }
    } else {
      this.mobile = this.$cookies.get('mobile') && (this.$cookies.get('mobile').length > 10)
      this.getData()
      this.$on('change', () => {
        if (this.$route.query.fromOpenId) {
          localStorage.setItem("fromOpenId", this.$route.query.fromOpenId);
        }
        this.geet()
      })
      if (!sessionStorage.getItem('viewLog')) {
        sessionStorage.setItem('viewLog', 1)
        this.Action(3);
      }
      this.$on('tel', () => {
        this.Action(8);
      })
    }

  },
  beforeDestroy() {
    clearInterval(this.time)
  },
  methods: {
    shareLog() {
      let url = this.$route.name
      if (url == 'details') {
        this.Action(11)
      } else if (url == 'card') {
        this.Action(9)
      }
    },
    geet() {
      if (!this.form.businessCard.id) {
        return false
      }
      var date = new Date();
      let Month = date.getMonth() + 1;
      let day = date.getDate(); //获取当前日(1-31)
      this.$api.get(`/api/crm/ladingPage/getReplyData?mobile=${this.$cookies.get('mobile')}&businessCardId=${this.form.businessCard.id}`).then(res => {
        $url = window.location.href.slice(0, window.location.href.indexOf('?'))
        if (window.location.href.indexOf('businessCardId') == -1) {
          $url = $url + `?id=${this.$route.query.id}&fromOpenId=${this.$cookies.get('openId')}&ladingPageId=${localStorage.getItem("ladingPageId")}&tenantId=${localStorage.getItem("tenantId")}`
        } else {
          $url = $url + `?id=${this.$route.query.id}&businessCardId=${res.result}&channelId=${this.$route.query.channelId}&articleId=${this.$route.query.articleId}&tenantId=${localStorage.getItem("tenantId")}`
        }
        if (window.location.hash.indexOf('card') != -1) {
          this.title = `${this.form.businessCard.company} ${this.form.businessCard.name} 为您敬上名片！`
          if (this.form.businessCard.shareWork) {
            this.desc = this.form.businessCard.shareWork
          } else {
            this.desc = `职务：${this.form.businessCard.post}\n手机：${this.form.businessCard.mobile}`
          }
        } else {
          this.title = `${Month}.${day}热点播报：` + this.form.article.name
          this.desc = this.form.article.summary
          if (this.form.article.$id) {
            $url = window.location.origin + window.location.pathname + `#/?id=${res.result}&fromOpenId=${this.$cookies.get('openId')}&ladingPageId=${localStorage.getItem("ladingPageId")}&tenantId=${localStorage.getItem("tenantId")}`
          } else {
            $url = window.location.origin + window.location.pathname + `#/details?id=${this.$route.query.id}&businessCardId=${res.result}&channelId=${this.$route.query.channelId}&articleId=${this.$route.query.articleId}&fromOpenId=${this.$cookies.get('openId')}&tenantId=${localStorage.getItem("tenantId")}`
          }
        }
        let logo = this.form.businessCard.companyLogo || 'http://www.zhongpukeji.cn/img/icon/logo.png'
        let that = this
        wx.onMenuShareAppMessage({
          title: this.title, // 分享标题
          desc: this.desc, // 分享描述
          link: $url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: logo, // 分享图标
          success: function (res) {
            console.log('分享回调onMenuShareAppMessage：', JSON.stringify(res))
            // 用户点击了分享后执行的回调函数
            that.shareLog()
          }
        });
        wx.onMenuShareTimeline({
          title: this.title, // 分享标题
          desc: this.desc, // 分享描述
          link: $url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: logo, // 分享图标
          success: function (res) {
            console.log('分享回调onMenuShareTimeline：', JSON.stringify(res))
            // 用户点击了分享后执行的回调函数
            that.shareLog()
          }
        })
        wx.updateTimelineShareData({
          title: this.title, // 分享标题
          desc: this.desc, // 分享描述
          link: $url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: logo, // 分享图标
          success: function (res) {
            console.log('分享回调updateTimelineShareData：', JSON.stringify(res))
            // 设置成功
            // that.shareLog()
          }
        })
        //分享给好友
        wx.updateAppMessageShareData({
          title: this.title, // 分享标题
          desc: this.desc, // 分享描述
          link: $url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: logo, // 分享图标
          success: function (res) {
            console.log('分享回调updateAppMessageShareData：', JSON.stringify(res))
            // 设置成功
            // that.shareLog()
          }
        })
      })
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    getData() {
      if (window.location.href.indexOf('businessCardId') == -1 || window.location.href.indexOf('articleId') == -1) {
        return false
      }
      console.log('获取文章详情')
      let id = this.$route.query.id;
      let channelId = this.$route.query.channelId || 0
      localStorage.setItem("ladingPageId", id || 0);
      localStorage.setItem("channelId", channelId);
      let businessCardId = this.$route.query.businessCardId;
      this.$api.get(`/api/crm/ladingPage/getData?ladingPageId=${id}&businessCardId=${businessCardId}&channelId=${channelId}&articleId=${this.$route.query.articleId}`).then((res) => {
        this.$nextTick(() => {
          this.skeleton = false;
        });
        if (res.result) {
          this.form = res.result;
          this.form.ad = this.form.ad || {}
          this.form.from = this.form.from || {}
          localStorage.setItem("tel", this.form.businessCard.mobile);
          localStorage.setItem("businessCardId", this.form.businessCard.id);
          localStorage.setItem("www", this.form.businessCard.www);
          localStorage.setItem("id", this.form.id);
          localStorage.setItem("address", `http://apis.map.qq.com/uri/v1/marker?marker=coord:${this.form.businessCard.lat},${this.form.businessCard.lng};title:${this.form.businessCard.company};addr:${this.form.businessCard.address}`);
          this.share()
          this.geet()
        }
      });
    },
    share() {
      this.$api
        .get("/api/wechat/share/get", {
          url: window.location.href.split("#")[0],
        })
        .then((res) => {
          console.log('分享数据获取：', res.result)
          let that = this
          if (window.location.hash.indexOf('details') == -1) {
            document.title = `${this.form.businessCard.name}的名片`;
            this.title = `为您推荐${this.form.businessCard.name}的个人名片`
            this.desc = this.form.businessCard.shareWork
          } else {
            this.title = this.form.article.name
            this.desc = this.form.article.summary
            document.title = this.title;
          }
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.result.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: res.result.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.result.nonceStr, // 必填，生成签名的随机串
            signature: res.result.signature, // 必填，签名，见附录1
            jsApiList: [
              "checkJsApi",
              "onMenuShareTimeline",
              "onMenuShareAppMessage",
              "onMenuShareQQ",
              "onMenuShareWeibo",
              "onMenuShareQZone",
              "hideMenuItems",
              "showMenuItems",
              "hideAllNonBaseMenuItem",
              "showAllNonBaseMenuItem",
              "translateVoice",
              "startRecord",
              "stopRecord",
              "onVoiceRecordEnd",
              "playVoice",
              "onVoicePlayEnd",
              "pauseVoice",
              "stopVoice",
              "uploadVoice",
              "downloadVoice",
              "chooseImage",
              "previewImage",
              "uploadImage",
              "downloadImage",
              "getNetworkType",
              "openLocation",
              "getLocation",
              "hideOptionMenu",
              "showOptionMenu",
              "closeWindow",
              'updateTimelineShareData',
              "scanQRCode",
              "chooseWXPay",
              "openProductSpecificView",
              "addCard",
              "chooseCard",
              "openCard",
            ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
          wx.ready(function () {
            that.geet()
            // wx.onMenuShareAppMessage({
            //     title: that.title, // 分享标题
            //     desc: that.desc, // 分享描述
            //     link: $url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            //     imgUrl: logo, // 分享图标
            //     success: function () {
            //         // 用户点击了分享后执行的回调函数
            //     }
            // });
            // wx.onMenuShareTimeline({
            //     title: that.title, // 分享标题
            //     desc: that.desc, // 分享描述
            //     link: $url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            //     imgUrl: logo, // 分享图标
            //     success: function () {
            //         // 用户点击了分享后执行的回调函数
            //     }
            // })
            // wx.updateTimelineShareData({
            //     title: that.title, // 分享标题
            //     desc: that.desc, // 分享描述
            //     link: $url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            //     imgUrl: logo, // 分享图标
            //     success: function () {
            //         // 设置成功
            //     }
            // })
            // wx.updateAppMessageShareData({
            //     title: that.title, // 分享标题
            //     desc: that.desc, // 分享描述
            //     link: $url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            //     imgUrl: logo, // 分享图标
            //     success: function () {
            //         // 设置成功
            //
            //     }
            // })
          });
          wx.error(function (res) {
            console.log('打印错误消息:', res.errMsg); //打印错误消息。及把 debug:false,设置为debug:ture就可以直接在网页上看到弹出的错误提示
          });
        });
    },
    Action(type) {
      if (type === 9 && this.$cookies.get('openId') === this.form.businessCard.openId) {
        // 分享名片时判断是自己发出还是他人发出；自己发出则为“我发出”-14：发送名片；他人发出则为“被分享”-9：分享名片
        type = 14
      }

      let obj = {
        //     @ApiModelProperty("渠道ID")
        // private Long channelId;
        articleId: this.$route.query.articleId || 0,
        channelId: localStorage.getItem("channelId") || 0,
        // @ApiModelProperty("IP")
        // private String ip;

        // @ApiModelProperty("落地页")
        // private Long ladingPageId;
        ladingPageId: localStorage.getItem("ladingPageId") || 0,
        // @ApiModelProperty("URL")
        // private String url;
        url: window.location.href,
        // @ApiModelProperty("动作")
        // private Integer action;
        action: type,
        businessCardId: this.$route.query.businessCardId || this.$route.query.id,

        // @ApiModelProperty("进入页面时间")
        // private Date entryTime;


        // @ApiModelProperty("退出页面时间")
        // private Date leaveTime;


        // @ApiModelProperty("页面ID")
        // private String pageId;
        pageId: this.$pageId,
        fromOpenId: localStorage.getItem("fromOpenId") || this.$cookies.get('openId')
      }

      this.$api.post('/api/crm/accessLog/save', {
        ...obj,
        openId: this.$cookies.get('openId'),
        unionId: this.$cookies.get('unionId')
      })
    }
  },
};